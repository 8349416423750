import { useActionTracker } from '@/helper/actionTracker';
import { useActivityLog } from '@/modules/general/hooks';
import { cx, getCookies, TextDS2 } from '@hol-jsp/dashboard-dsl';
import { useRouter } from 'next/router';
import { ThemeButton } from '../ThemeButton';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { getUserEntryPoint } from '@/helper/index';

export function NotFound() {
  const router = useRouter();
  const { t } = useTranslation();
  const { mutate } = useActivityLog();
  const isLoggedIn = getCookies('token');

  const handleBackToLogin = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useActionTracker({
      action: 'back-to-login',
      type: 'back-to-login',
      category: 'Page 404 - Back To Login',
      status: 'success',
      mutate,
    });

    isLoggedIn ? router.push(getUserEntryPoint(false)) : router.push('/login');
  };

  return (
    <div className="bg-neutral-50">
      <div
        className={cx(
          'w-full mx-auto flex flex-col items-center justify-center text-center bg-white h-[100vh]'
        )}
      >
        <Image
          src="/images/error-404.png"
          alt="Error 404"
          width={326}
          height={250}
          className="block mb-8"
        />
        <TextDS2
          agDesktop="Desktop/Title 1/Extra Bold"
          agMobile="Desktop/Title 1/Extra Bold"
          color="Neutral/400"
          className="mb-2"
        >
          {t('404.title')}
        </TextDS2>
        <TextDS2
          agDesktop="Desktop/Body 2/Medium"
          agMobile="Desktop/Body 2/Medium"
          color="Neutral/300"
          className="mb-6 max-w-[545px]"
        >
          {t('404.subtitle')}
        </TextDS2>
        <ThemeButton onClick={handleBackToLogin} variant="outlined">
          {t('404.button')}
        </ThemeButton>
      </div>
    </div>
  );
}
