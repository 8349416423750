import FingerPrintJS from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';
import { dateParser } from '@/helper/utils';

interface IActionTracker {
  type: string;
  category: string;
  action: string;
  status: string;
  data?: Record<any, any | undefined>;
  mutate: any;
}

const useActionTracker = async ({
  type,
  category,
  action,
  status,
  data,
  mutate,
}: IActionTracker) => {
  const parser = new UAParser();
  const fp = await FingerPrintJS.load();
  const result = await fp.get();
  const deviceType = parser.getUA();

  if (result.visitorId !== '') {
    mutate({
      action,
      type,
      category,
      url: window.location.href,
      status,
      data: {
        ...data,
      },
      deviceId: result.visitorId,
      deviceType,
    });
  }
};

export { useActionTracker };
